// @ts-nocheck
import { AppProps } from 'next/app';

import { AuthProvider } from 'hooks/useAuth';
import { TeamProvider } from 'hooks/useTeam';
// import { ToastProvider } from 'hooks/useToast';
import 'react-toastify/dist/ReactToastify.css';
import 'css/tailwind.css';
import StatCounter from 'utils/statcounter';
import { StateMachineProvider, createStore } from 'little-state-machine';
import { ToastContainer } from 'react-toastify';
import '../css/youtube.css';

createStore({});

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <StateMachineProvider>
      <AuthProvider>
        <TeamProvider>
          <Component {...pageProps} />
          <StatCounter></StatCounter>
          <ToastContainer />
        </TeamProvider>
      </AuthProvider>
    </StateMachineProvider>
  );
}
