import Script from 'next/script';

const StatCounter = () => {
  return (
    <>
      <Script id="sc" type="text/javascript">
        var sc_project=12723044; var sc_invisible=1; var sc_security="d6e423f5";
      </Script>
      <Script
        type="text/javascript"
        src="https://www.statcounter.com/counter/counter.js"
        async
        strategy="lazyOnload"
      ></Script>
      <noscript>
        <div className="statcounter">
          <a
            title="Web Analytics"
            href="https://statcounter.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="statcounter"
              src="https://c.statcounter.com/12723044/0/d6e423f5/1/"
              alt="Web Analytics"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </a>
        </div>
      </noscript>
    </>
  );
};

export default StatCounter;
